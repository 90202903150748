<template>
  <v-container fluid>
    <LoadingModal :visible="loadDemanda" />
    <v-row>
      <v-col>
        <v-card>
          <v-card-title v-if="demanda.rank">
            Demanda {{ demanda.code }} - {{ demanda.rank.name }}
            <v-chip
              class="ma-2"
              color="red"
              text-color="white"
              v-show="verificaSeAtrasado"
            >
              Atrasado
            </v-chip>
          </v-card-title>
          <v-card>
            <v-tabs v-model="tab" background-color="transparent" grow>
              <v-tab>
                Dados Basicos
              </v-tab>
              <v-tab>
                Solicitantes
              </v-tab>
              <v-tab>
                Responsaveis
              </v-tab>
              <v-tab>
                Historico
              </v-tab>
              <v-tab>
                Arquivos
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card>
                  <v-card-title>
                    <h4>Dados Basicos</h4>
                    <v-btn
                      color="primary"
                      class="ml-4"
                      small
                      @click="editDemanda = !editDemanda"
                    >
                      Editar
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <span class="font-weight-bold"> Codigo:</span>
                        {{ demanda.code }}
                      </v-col>
                      <v-col cols="12">
                        <span class="font-weight-bold"> Descrição </span>
                        {{ demanda.description }}
                        <div v-if="editDemanda">
                          <v-text-field
                            class="mt-6"
                            prepend-icon=""
                            :autofocus="true"
                            label="Descrição"
                            v-model="demanda.description"
                          ></v-text-field>
                          <v-btn color="success" small @click="updateDemanda()">
                            Alterar
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <span class="font-weight-bold"> Iniciado: </span>
                        {{ demanda.created_at | formatDate }}
                      </v-col>
                      <v-col cols="12">
                        <span class="font-weight-bold"> Prazo: </span>
                        {{ demanda.deadline | formatDate }}
                        <Prazo
                          :demanda="demanda.id"
                          v-model="demanda.deadline"
                          v-if="editDemanda"
                        />
                      </v-col>
                      <v-col cols="12">
                        <span class="font-weight-bold">
                          Ultima Atualização:
                        </span>
                        {{ demanda.updated_at | formatDate }}
                      </v-col>
                      <v-col cols="12" v-if="demanda.status">
                        <span class="font-weight-bold" v-if="demanda.status">
                          Status:
                        </span>
                        {{ demanda.status.name }}
                        <Collections
                          v-if="editDemanda"
                          :demanda="demanda.id"
                          :collection="'demands/status'"
                          :value="demanda.status_id"
                        />
                      </v-col>
                      <v-col cols="12" v-if="demanda.priority">
                        <span class="font-weight-bold"> Prioridade : </span>
                        {{ demanda.priority.name }}
                        <Collections
                          v-if="editDemanda"
                          :demanda="demanda.id"
                          :collection="'prioritys'"
                          :value="demanda.priority_id"
                        />
                      </v-col>
                      <v-col cols="12" v-if="demanda.agency">
                        <span class="font-weight-bold"> Órgão: </span>
                        {{ demanda.agency.name }}
                        <Collections
                          v-if="editDemanda"
                          :demanda="demanda.id"
                          :collection="'agencys'"
                          :value="demanda.agency_id"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- SOLICITANTES -->
              <v-tab-item>
                <v-card>
                  <v-card-title>
                    <h4>Solicitantes</h4>
                    <DialogSelect :demanda="demanda.id"></DialogSelect>
                  </v-card-title>
                  <v-card-text>
                    <ListaClientes
                      v-if="demanda.customers"
                      :list="demanda.customers"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- RESPONSAVEIS -->

              <v-tab-item>
                <v-card>
                  <v-card-title>
                    <h4>Responsaveis</h4>
                    <DialogSelectUser :demanda="demanda.id" />
                  </v-card-title>
                  <v-card-text>
                    <ListaResponsaveis
                      v-if="demanda.users"
                      :list="demanda.users"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- Historicos -->

              <v-tab-item>
                <v-card>
                  <v-card-title>
                    <h4>Historicos</h4>
                    <DialogNewHistoric :demanda="demanda.id" />
                  </v-card-title>
                  <v-card-text>
                    <ListaHistoricos
                      @load="getDemanda"
                      v-if="demanda.histories"
                      :list="demanda.histories"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card>
                  <v-card-title>
                    <h4>Arquivos</h4>
                  </v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item
                        v-for="(file, index) in demanda.files"
                        :key="file.id + 'file'"
                      >
                        <v-list-item-avatar>
                          <v-btn disabled fab small>
                            {{ index + 1 }}
                          </v-btn>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ file.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-btn @click="openFile(file)" color="success">
                              Abrir
                            </v-btn>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import instanceAxios from "../../plugins/axios";
import LoadingModal from "../../components/Loading/Modal/Index.vue";
import moment from "moment";
import ListaClientes from "../../components/Listas/ListaClientes.vue";
import ListaResponsaveis from "../../components/Listas/ListaResponsaveis.vue";
import ListaHistoricos from "../../components/Listas/ListaHistoricos.vue";
import Prazo from "../../components/Demandas/Inputs/prazo.vue";
import Collections from "../../components/Demandas/Inputs/collections.vue";
import DialogSelect from "./DialogSelect.vue";
import DialogSelectUser from "./DialogSelectUser.vue";
import DialogNewHistoric from "../../components/Demandas/Dialogs/DialogNewHistoric.vue";
import ToastService from "../../services/ToastService";

export default {
  components: {
    LoadingModal,
    ListaClientes,
    ListaResponsaveis,
    ListaHistoricos,
    Prazo,
    Collections,
    DialogSelect,
    DialogSelectUser,
    DialogNewHistoric,
  },

  data() {
    return {
      demanda: {},
      loadDemanda: false,
      id: null,
      tab: 0,
      editDemanda: false,
    };
  },

  computed: {
    verificaSeAtrasado: function() {
      return moment(this.demanda.deadline).isBefore();
    },
  },

  watch: {},

  filters: {
    formatDate: function(el) {
      return moment(el).format("DD/MM/YYYY HH:mm");
    },
  },

  created() {
    this.getDemanda();

    this.$root.$on("updateDemand", () => this.getDemanda());
  },

  methods: {
    async getDemanda() {
      this.loadDemanda = true;

      const { data } = await instanceAxios.get(
        `demands/${this.$route.params.id}`
      );

      this.demanda = data;

      this.loadDemanda = false;
    },

    async updateDemanda() {
      await instanceAxios.put(`demands/${this.demanda.id}`, {
        description: this.demanda.description,
      });

      ToastService("Demanda atualizada com sucesso!", "success");
    },

    openFile(file) {
      window.open("https://wsjus.com.br/storage/demands/" + file.path);
      //window.open("http://localhost:8000/storage/demands/" + file.path);
    },
  },
};
</script>

<style></style>
