<template>
  <div>
    <v-list>
      <v-list-item v-for="item in list" :key="'item' + item.id">
        <v-list-item-avatar>
          <v-btn fab small :to="`/app/clientes`">
            <v-icon color="primary">
              fas fa-eye
            </v-icon>
          </v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.nome_administradora }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.pivot">
            {{ item.pivot.active ? 'Ativo' : 'Inativo' }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="item.pivot">
          <v-btn
            :color="item.pivot.active ? 'error' : 'success'"
            small
            :title="item.pivot.active ? 'Desativar' : 'Ativar'"
            @click="updateActive(item)"
          >
            <v-icon small>{{ item.pivot.active | pivotIconText }} </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import instanceAxios from "../../plugins/axios";
import ToastService from '../../services/ToastService';
export default {
  props: {
    list: {
      type: Array,
      default: function() {
        return [];
      },
      required: false,
    },
  },

  filters: {
    pivotIconText: function(value) {
      return value ? "fas fa-times" : "fas fa-check";
    },
  },

  methods: {
    async updateActive(item) {
      await instanceAxios.put(
        `demands/customers/${item.pivot.demand_id}/active/${item.pivot.customer_id}`
      );

      ToastService("Atualizado com sucesso!", "success");

      this.$root.$emit("updateDemand");
    },
  },
};
</script>

<style></style>
