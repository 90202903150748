<template>
  <v-row>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small color="primary" class="ml-6" dark v-bind="attrs" v-on="on">
          Novo
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h5">Selecionar</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" sm="12">
                <v-autocomplete
                  :items="customers"
                  item-text="name"
                  item-value="id"
                  label="Solicitantes"
                  multiple
                  v-model="selecteds"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Fechar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save()"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import instanceAxios from "../../plugins/axios";
import ToastService from "../../services/ToastService";
export default {
  props: {
    demanda: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    customers: [],
    selecteds: [],
  }),

  mounted() {
    this.get();
  },

  methods: {
    async get() {
      const { data } = await instanceAxios.get("customers");

      this.customers = data;
    },
    async save() {
      try {
        if (this.selecteds.length === 0) {
          ToastService("Selecione ao menos um solicitante", "error");
          return;
        }

        await instanceAxios.post(`demands/customers/${this.demanda}`, {
          customers: this.selecteds,
        });

        this.$root.$emit("updateDemand");

        ToastService("Adicionado com sucesso", "success");
        this.dialog = false;
      } catch (error) {
        ToastService("Erro ao tentar atualizar", "error");
      }
    },
  },
};
</script>
