<template>
  <v-row>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small color="primary" class="ml-6" dark v-bind="attrs" v-on="on">
          Novo
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h5">Novo Historico</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col cols="12">
                <v-text-field
                  prepend-icon=""
                  :autofocus="true"
                  label="Titulo*"
                  v-model="historico.title"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  prepend-icon=""
                  :autofocus="true"
                  label="Descrição*"
                  v-model="historico.content"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Fechar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save()"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import instanceAxios from "../../../plugins/axios";
import ToastService from "../../../services/ToastService";

export default {
  props: {
    demanda: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    customers: [],
    selecteds: [],
    historico: {},
  }),

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },

  mounted() {
    this.get();
  },

  methods: {
    async get() {
      const { data } = await instanceAxios.get("customers");

      this.customers = data;
    },
    async save() {
      try {
        await instanceAxios.post(`demands/histories`, {
          demand_id: this.demanda,
          ...this.historico,
          user_id: this.me.id,
        });

        this.$root.$emit("updateDemand");

        ToastService("Adicionado com sucesso", "success");
        this.dialog = false;
      } catch (error) {
        ToastService("Erro ao tentar atualizar", "error");
      }
    },
  },
};
</script>
