<template>
  <v-container>
    <v-row class="d-flex justify-center align-center">      
      <v-col cols="12" sm="4" md="4">
        <v-text-field v-model="date" type="date"> </v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-text-field v-model="time" label="Horario" type="time">
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-btn color="success" small @click="updateDemanda()"> Alterar </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import instanceAxios from "../../../plugins/axios";
import ToastService from '../../../services/ToastService';

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    demanda: {
      type: [String, Number],
      required: true,
    },
  },

  watch: {
    value() {      
      this.date = this.dateOnly;
      this.time = this.timeOnly;
    },
  },

  computed: {
    dateOnly() {
      return moment(this.value).format("YYYY-MM-DD");
    },

    timeOnly() {
      return moment(this.value).format("HH:mm");
    },
  },

  data() {
    return {
      date: moment(this.value).format("YYYY-MM-DD"),
      time: moment(this.value).format("HH:mm"),
    };
  },

  methods: {
    emmit(value, type) {
      if (type == "date") {
        this.date = `${value} ${this.timeOnly}`;
      } else {
        this.date = `${this.dateOnly} ${value}`;
      }
    },

    async updateDemanda() {

      await instanceAxios.put(`demands/${this.demanda}`, {
        deadline: `${this.date} ${this.time}`,
      });

      ToastService('Demanda atualizada com sucesso!', 'success')

      this.$root.$emit('updateDemand');
    },
  },
};
</script>

<style></style>
