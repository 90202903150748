<template>
  <div>
    <v-list>
      <v-list-item v-for="(item, index) in list" :key="'item' + item.id">
        <v-list-item-avatar>
          <v-btn fab small>
            {{ list.length - index }}
          </v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
          <v-list-item-subtitle style="text-wrap: wrap;">
            {{ item.content }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.user">
            {{ item.user.name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ item.created_at | formatDate }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            :color="'error'"
            small
            title="Excluir"
            @click="updateActive(item)"
          >
            <v-icon small>fas fa-times </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import moment from "moment";
import instanceAxios from "../../plugins/axios";
export default {
  props: {
    list: {
      type: Array,
      default: function() {
        return [];
      },
      required: false,
    },
  },

  filters: {
    formatDate: function(el) {
      return moment(el).format("DD/MM/YYYY HH:mm");
    },
  },
  methods: {
    async updateActive(item) {
      await instanceAxios.delete(`demands/histories/${item.id}`);

      this.$emit("load");
    },
  },
};
</script>

<style></style>
