<template>
  <v-container>
    <v-row class="d-flex justify-center align-center">
      <v-col cols="12" sm="6">
        <v-select
          :items="items"
          item-text="name"
          item-value="id"
          v-model="itemId"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-btn color="success" small @click="updateDemanda()"> Alterar </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import instanceAxios from "../../../plugins/axios";
import ToastService from "../../../services/ToastService";
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },

    demanda: {
      type: [String, Number],
      required: true,
    },

    collection: {
      type: String,
      required: true,
    },
  },

  watch: {
    value(newValue) {
      this.itemId = newValue;
    },
  },

  data() {
    return {
      itemId: this.value,
      items: [],
    };
  },

  async mounted() {
    const { data } = await instanceAxios.get(`${this.collection}`);

    this.items = data;
  },

  methods: {
    async updateDemanda() {
      await instanceAxios.put(`demands/${this.demanda}`, {
        [this.collection]: this.itemId,
      });

      ToastService("Demanda atualizada com sucesso!", "success");

      this.$root.$emit("updateDemand");
    },
  },
};
</script>

<style></style>
